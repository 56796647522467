import { render, staticRenderFns } from "./SettingCard.vue?vue&type=template&id=527bcc34&scoped=true&"
import script from "./SettingCard.vue?vue&type=script&lang=ts&"
export * from "./SettingCard.vue?vue&type=script&lang=ts&"
import style0 from "./SettingCard.vue?vue&type=style&index=0&id=527bcc34&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527bcc34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VChip,VHover,VIcon,VListItem,VListItemContent,VListItemTitle,VSpacer})
