
import { Vue, Component, Prop } from "vue-property-decorator";
import PropertyCollection from "@/components/PropertyCollection.vue";
import PropertyItem from "@/components/PropertyItem.vue";
import { DecoratedAsset, DecoratedProperty } from "@/types";
import { getProperty } from "@/config/asset";

@Component({
  components: {
    PropertyCollection,
    PropertyItem
  }
})
export default class DevicePropertySection extends Vue {
  @Prop({ type: Object, required: true })
  readonly device: DecoratedAsset;

  get iaqProperty(): DecoratedProperty | null {
    const iaqSensor = getProperty(this.device, "iaq_sensor").value as string;

    switch (iaqSensor) {
      case "CO2_2000":
      case "CO2_1100":
        return getProperty(this.device, "co2");
      case "RH":
        return getProperty(this.device, "rh");
      case "VOC":
        return getProperty(this.device, "voc");
      case "EXT":
        return getProperty(this.device, "external_sensor_percent");
    }

    return null;
  }

  get thisMonthLastYear(): string {
    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    return new Intl.DateTimeFormat(this.$i18n.locale, { month: "short", year: "numeric" }).format(lastYear);
  }
}
