
import { Vue, Component, Prop } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
import {
  mdiWeatherWindy,
  mdiThermometer,
  mdiScent,
  mdiCrowd,
  mdiSwitch,
  mdiClock,
  mdiSpeedometer,
  mdiAlphaE
} from "@mdi/js";
import SettingCard from "@/components/SettingCard.vue";
import { DecoratedAsset, SettingType } from "@/types";
import { DEFAULT_UNIT, getProperty } from "@/config/asset";

@Component({
  components: {
    SettingCard
  }
})
export default class DeviceSettingsSection extends Vue {
  @Prop({ type: Object, required: true })
  readonly device: DecoratedAsset;

  icons = {
    mdiWeatherWindy,
    mdiThermometer,
    mdiScent,
    mdiCrowd,
    mdiSwitch,
    mdiClock,
    mdiSpeedometer,
    mdiAlphaE
  };

  get settings(): any[] {
    const operatingMode = getProperty(this.device, "operating_mode");

    return [
      {
        setting: getProperty(this.device, "target_temp", { unit: DEFAULT_UNIT }),
        labelKey: "fields.ventilator.target_temp.long_label",
        labelValues: { location: this.tempSensorLocationString },
        icon: mdiThermometer
      },
      {
        setting: getProperty(this.device, "target_airflow"),
        icon: mdiWeatherWindy,
        disabled: operatingMode.value !== "CAV"
      },
      {
        setting: getProperty(this.device, "flow_offset"),
        icon: mdiWeatherWindy
      },
      {
        setting: getProperty(this.device, "temp_flow_reduction_enabled"),
        secondarySetting: getProperty(this.device, "temp_flow_reduction_active"),
        settingType: SettingType.EnabledActive,
        icon: mdiScent
      },
      {
        setting: getProperty(this.device, "ext_is_occupancy"),
        secondarySetting: getProperty(this.device, "fire_occupancy_sensor_value"),
        settingType: SettingType.EnabledActive,
        icon: mdiCrowd
      },
      {
        setting: operatingMode,
        icon: mdiSwitch
      },
      {
        setting: getProperty(this.device, "schedule_enabled"),
        secondarySetting: getProperty(this.device, "schedule_active"),
        settingType: SettingType.EnabledActive,
        icon: mdiClock
      },
      {
        setting: getProperty(this.device, "boost_active"),
        settingType: SettingType.Active,
        icon: mdiSpeedometer
      },
      {
        setting: getProperty(this.device, "economizer_enabled"),
        secondarySetting: getProperty(this.device, "economizer_active"),
        settingType: SettingType.EnabledActive,
        icon: mdiAlphaE
      }
    ];
  }

  get tempSensorLocationString(): TranslateResult {
    const location = getProperty(this.device, "temp_sensor_location").value as string;
    return this.$t(`fields.ventilator.temp_sensor_location.duct_options.${location}`);
  }
}
