
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { isNil } from "lodash";
import { TranslateResult } from "vue-i18n";
import { DateTime, Duration } from "luxon";
import PropertyTimestamp from "./PropertyTimestamp.vue";
import { DecoratedAsset, DecoratedProperty, PropertyConfig, SettingType } from "@/types";
import { formatNumber } from "@/utils/number";

@Component({
  components: {
    PropertyTimestamp
  }
})
export default class SettingCard extends Vue {
  @Prop({ type: Object, required: true })
  readonly device: DecoratedAsset;

  @Prop({ type: Object, required: true })
  readonly setting: DecoratedProperty;

  @Prop({ type: Object })
  readonly secondarySetting?: DecoratedProperty;

  @Prop({ default: SettingType.Value })
  readonly settingType: SettingType;

  @Prop({ type: String })
  readonly labelKey?: string;

  @Prop({ type: Object })
  readonly labelValues?: Record<string, any>;

  @Prop({ type: String, required: false })
  readonly icon: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean;

  relativeTimestamp: Duration | null = null;
  interval: ReturnType<typeof setInterval> | null = null;

  SettingType = SettingType;

  get label(): TranslateResult {
    let key: string;

    if (isNil(this.labelKey)) {
      const namespace = this.device.config.i18nNamespace;
      key = `fields.${namespace}.${this.setting.name}.label`;
    } else {
      key = this.labelKey;
    }

    return this.$t(key, this.labelValues ?? {});
  }

  get settingConfig(): PropertyConfig {
    return this.setting.config;
  }

  get textColor(): string {
    return this.disabled ? "grey--text" : "";
  }

  get formattedValue(): string | null {
    const type = this.setting.config.dataType;
    if (type !== "number") return this.setting.value;
    return formatNumber(this.setting.value, { format: this.settingConfig.format });
  }

  get unitStr(): TranslateResult | null {
    if (!this.settingConfig.unit) return null;
    return this.$t(`units.${this.settingConfig.unit}`);
  }

  enabledDisabledKey(setting: DecoratedProperty): string {
    return setting.value ? "statuses.enabled" : "statuses.disabled";
  }

  activeInactiveKey(setting?: DecoratedProperty): string {
    return setting?.value ? "statuses.active" : "statuses.inactive";
  }

  get secondarySettingColor(): string {
    return this.secondarySetting?.value ? "green" : "red";
  }

  get isValueMissing(): boolean {
    return isNil(this.setting.value);
  }

  get timestampDate(): DateTime | null {
    const { timestamp } = this.secondarySetting ?? this.setting;
    const zone = this.$store.getters.timeZone;
    return timestamp ? DateTime.fromISO(timestamp).setZone(zone) : null;
  }

  @Watch("timestampDate", { immediate: true })
  @Watch("device.config.staleDataDuration")
  timestampChanged(): void {
    this.updateRelativeTimestamp();

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    if (this.timestampDate) this.interval = setInterval(this.updateRelativeTimestamp, 10000);
  }

  updateRelativeTimestamp(): void {
    this.relativeTimestamp = this.timestampDate?.diffNow() ?? null;
  }
}
